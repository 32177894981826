import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faCheck,
  faRotateLeft,
} from "@fortawesome/free-solid-svg-icons";
import { useContext } from "react";
import DataContext from "../context/DataContext";

const Task = ({ id, name, completed, index, createdAt }) => {
  const { todos, setTodos } = useContext(DataContext);
  const handleComplete = (id) => {
    setTodos(
      todos.map((todo) => {
        if (todo.id === id) {
          return { ...todo, completed: !completed };
        } else {
          return todo;
        }
      })
    );
  };
  const deleteTodo = (id) => {
    const newTodoList = todos.filter((todo) => {
      if (todo.id === id) {
        return false;
      } else {
        return true;
      }
    });
    setTodos(newTodoList);
  };
  return (
    <article>
      <div className="task-container">
        <p className="task-number">{index + 1}.</p>
        <div className="task-name-controls-container">
          <div className="task-name">
            <p className={`${completed ? "task-completed" : ""}`}>{name}</p>
            <p>
              <span className="created-at">Created at: {createdAt}</span>
            </p>
          </div>
          <div className="controls">
            <button
              onClick={() => {
                handleComplete(id);
              }}
              className={`${
                completed ? "button-completed" : "button-not-completed"
              }`}
            >
              {completed ? (
                <FontAwesomeIcon icon={faRotateLeft} />
              ) : (
                <FontAwesomeIcon icon={faCheck} />
              )}
            </button>
            <button
              onClick={() => {
                deleteTodo(id);
              }}
            >
              <FontAwesomeIcon icon={faTrash} />
            </button>
          </div>
        </div>
      </div>
    </article>
  );
};

export default Task;
