import { useContext } from "react";
import Task from "./Task";
import DataContext from "../context/DataContext";

const TodoList = () => {
  const { todos } = useContext(DataContext);
  const dataLength = todos.length;
  return (
    <section className="todoList">
      {dataLength === 0 ? (
        <article className="no-tasks">
          <h2>
            Go ahead, <br />
            make your day!
          </h2>
          <img
            src={require("../assets/coffee-icon-purple.png")}
            alt="coffee"
            width="100"
            height="auto"
          />
        </article>
      ) : (
        <h2>
          You have {dataLength} {dataLength === 1 ? "task" : "tasks"}:
        </h2>
      )}
      {todos?.map((task, index) => {
        return (
          <Task
            key={index}
            id={task.id}
            name={task.name}
            index={index}
            completed={task.completed}
            createdAt={task.createdAt}
          />
        );
      })}
    </section>
  );
};

export default TodoList;
