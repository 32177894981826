import React from "react";

const Header = () => {
  return (
    <header>
      <img
        src={require("../assets/logo.png")}
        alt="todo list logo"
        width="156"
        height="auto"
      />
    </header>
  );
};

export default Header;
