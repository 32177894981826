import "./App.css";
import Header from "./components/Header";
import NewTask from "./components/NewTask";
import TodoList from "./components/TodoList";
import { DataProvider } from "./context/DataContext";

function App() {
  return (
    <div className="app">
      <Header />
      <DataProvider>
        <NewTask />
        <TodoList />
      </DataProvider>
    </div>
  );
}

export default App;
