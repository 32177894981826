import { useState, useContext } from "react";
import DataContext from "../context/DataContext";
import Moment from "moment";
import InputNote from "./InputNote";

const NewTask = () => {
  const [newTask, setNewTask] = useState({});
  const [showNotification, setShowNotification] = useState(false);
  const { todos, setTodos } = useContext(DataContext);

  const handleInput = (event) => {
    const timeAndDate = Moment().format("HH:mm:ss, MMMM Do YYYY");
    setNewTask({
      id: Math.random(),
      name: event.target.value,
      completed: false,
      createdAt: timeAndDate,
    });
  };
  const handleAddTask = (e) => {
    e.preventDefault();
    if (newTask.name?.length > 2) {
      setTodos([...todos, newTask]);
      setNewTask({});
    } else {
      setShowNotification(true);
    }
  };
  return (
    <section className="addTask">
      <form>
        <input
          name="todo"
          type="text"
          placeholder=" Your new task..."
          onChange={handleInput}
          value={newTask.name || ""}
          onFocus={() => setShowNotification(false)}
        />
        <button type="submit" onClick={handleAddTask}>
          Add task
        </button>
      </form>
      {showNotification && <InputNote />}
    </section>
  );
};

export default NewTask;
